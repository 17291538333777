<template>
	<div>
		<div class="d-flex">
			<div>
				<slot name="operateButtons"></slot>
			</div>
			<div class="d-flex align-items-center ml-auto">
				<div class="d-flex" v-if="!searchMore">
					<el-input v-model="keyword" size="small" :placeholder="placeholder" prefix-icon="el-icon-search"></el-input>
					<el-button type="primary" size="small" class="ml-2" @click="$emit('search',keyword)">搜索</el-button>
				</div>
				<el-button type="primary" class="ml-2" size="small" v-if="isSuper" @click="hiddenMore">{{searchMore?'收起':'高级搜索'}}</el-button>
			</div>
		</div>
		<collapse-transition>
			<div v-if="searchMore">
				<slot name="searchMore"></slot>
				<div class="d-flex align-items-center mt-3 justify-content-end">
					<el-button type="primary" size="small" class="ml-2" @click="$emit('superSearch')">搜索</el-button>
					<el-button type="primary" size="small" @click="$emit('clear')">重置</el-button>
				</div>
			</div>
		</collapse-transition>
	</div>
</template>

<script>
	import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
	export default {
		components:{CollapseTransition},
		props:{
			placeholder:{
				type:String,
				default:'请输入搜索内容'
			},
			isSuper:{
				type:Boolean,
				default:true
			}
		},
		data() {
			return {
				searchMore:false,
				keyword:''
			}
		},
		methods:{
			hiddenMore() {
				this.searchMore = !this.searchMore
				this.$emit('clear')
			}
		}
	}
</script>

<style>
</style>
